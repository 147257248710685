<template>
    <r-e-dialog title="合同相关账单" :visible.sync="dialogVisible" show-footer top="5vh" width="1500px"
                @click-submit="handleSubmitPublish" @click-cancel="handleCancel" @close="handleCancel">
        <r-e-table ref="communityTableRef" :dataRequest="getPaidTableData" :columns="houseBillColumns" :height="500"
                   :showPagination="false" show-summary :summary-method="getSummaries">
            <el-table-column prop="comment" label="账单备注" :show-overflow-tooltip="true"/>
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="look(row)">详情</span>
                        <span class="table-btn" v-if="row.type==='500512'" @click="transfer(row)">转移</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <r-e-dialog title="详情" class="layer-contract-detail" :visible.sync="detailsDialogVisible" show-footer top="10vh"
                    width="1700px" @click-submit="detailsDialogVisible=false" @click-cancel="detailsDialogVisible=false"
                    @close="detailsDialogVisible=false">
            <bill-details ref="billDetails" :billData="billData"/>
        </r-e-dialog>
        <layer-transfer-deposit-bill ref="layerTransferDepositBill" @clickCancel="getPaidTableData"/>
    </r-e-dialog>
</template>

<script>
import {houseBillColumns} from "@/views/rental-management/house-resource/data";
import {getPaybillsByContract} from "@/api/bill";
import {numberFormat} from "@custom/index";

export default {
    name: "dialog-look-zhangdan",
    data() {
        return {
            dialogVisible: false,
            houseBillColumns: houseBillColumns(this),
            billData: null,
            detailsDialogVisible: false,
        }
    },
    components: {
        billDetails: () => import("@/views/bill-management/rent-withdrawal/components/billDetails"),
        layerTransferDepositBill: () => import('@/views/bill-management/payment-record/components/layer-transfer-deposit-bill'),
    },
    methods: {
        //打开账单列表弹窗
        openDialog(data) {
            let {uuid} = data;
            this.uuid = uuid;
            this.dialogVisible = true;
        },

        //账单列表查询请求
        getPaidTableData(params) {
            params.statusCode = "";
            params.type = "";
            return getPaybillsByContract(this.uuid, params);
        },

        //账单列表查询方法
        handleSearch() {
            this.$refs["communityTableRef"].getTableData(true);
        },

        //点击账单列表弹窗确认按钮事件
        handleSubmitPublish() {
            //关闭账单列表弹窗
            this.dialogVisible = false;
        },

        //点击账单列表弹窗取消按钮事件
        handleCancel() {
            //关闭账单列表弹窗
            this.dialogVisible = false;
        },

        // 点击账单详情事件
        look(data) {
            //数据传递
            this.billData = data;
            //打开账单详情弹窗
            this.detailsDialogVisible = true;
        },

        //账单列表合计方法
        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) return prev + curr;
                        else return prev;
                    }, 0);
                    sums[index] = numberFormat(sums[index] / 100);
                } else sums[index] = '';

            });
            return sums;
        },

        //点击账单转移事件
        transfer(data) {
            //调用押金账单转移弹窗组件方法，打开押金账单转移弹窗
            this.$refs["layerTransferDepositBill"].openDialog(data);
        }
    },
    watch: {}
}
</script>

<style scoped>

</style>